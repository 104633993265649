import React from 'react';
import { navigate, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { pageview } from '../utils/scripts';
import { isLoggedIn } from '../utils/auth';

class IndexPage extends React.Component {
  componentDidMount() {
    if (isLoggedIn()) {
      navigate('/app/dashboard');
    }
    else {
      pageview();
    }
  }

  render() {
    const paragraphClasses = 'text-lg md:text-xl text-gray-700 leading-16 mb-16';
    const containerClasses = 'container px-2 mx-auto flex flex-wrap items-center';
    const widthClasses = 'lg:w-1/2 md:w-2/3 flex flex-col md:mx-auto w-full';
    const headlineCLasses = 'text-center text-3xl md:text-4xl leading-9 font-black text-gray-900 mb-16 font-headline';
    const subHeadlingClasses = 'text-center text-2xl md:text-2xl leading-9 font-bold text-gray-900 mb-16 font-headline';
    const joinBtnClasses = 'bg-indigo-700 hover:bg-indigo-500 text-white font-extrabold text-3xl text-center py-4 px-2 md:px-24 rounded-full inline-block w-full md:inline';
    const fakeJoinBtnClasses = 'text-indigo-700 font-extrabold text-xl text-center py-4 inline-block w-full md:inline';
    const headerBgStyles = {
      backgroundImage: `url('https://images.ctfassets.net/7tvkfsva90tt/3OoaXFUrq5R2gh2tSCSu7k/b8a3b8a707c7db14233ec90ebd5ded69/sidewall-club-bg.png')`,
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
    };
    const hugeNumberStyles = {
      fontSize: '5rem',
      lineHeight: '5rem',
    };
    const questionClasses = 'text-2xl font-bold text-gray-900 mb-4 font-headline';
    const answerClasses = 'text-lg md:text-xl text-gray-700 leading-16 mb-8';
    return (
      <Layout>
        <SEO title="Sidewall Club" />
        <section className="bg-white lea">
          <div className="container mx-auto flex items-center justify-center flex-col">
            <div className="flex flex-wrap w-full pt-24 px-4 md:px-48" style={headerBgStyles}>
              <div className="w-full lg:w-1/2 mb-24">
                <h2 className={headlineCLasses}>
                  Join the club and receive exclusive discounts from Sidewall Pizza Company every month
                  </h2>
                <p className={paragraphClasses}>And, <span className="underline">new for 2021</span>, you will also receive access to select discounts, not found anywhere else,
              from other area businesses that we love to support.</p>
                {/* <Link to="/join" className={joinBtnClasses}>Join Now</Link> */}
                <p className={fakeJoinBtnClasses}>New Memberships Available Soon</p>
              </div>
              <div className="w-full lg:w-1/2 px-8 pb-24">
                <img className="mx-auto w-80" src="https://images.ctfassets.net/7tvkfsva90tt/4pu9Nsp4jhoVBkTWN8zSCW/31b0cf75c0a7c8dd77bf6131eab0341b/tmp-sidewall-club.png" alt="phone app" />
              </div>
            </div>

          </div>
        </section>

        <section className="pt-12 px-4 md:px-0" style={{ backgroundColor: `rgb(195,234,253)` }}>
          <div className={containerClasses}>
            <div className={widthClasses}>
              <h3 className={subHeadlingClasses}>Membership is only $5 a month</h3>
              <p className={paragraphClasses}>Membership in the Sidewall Club is an awesome value at only $5 a month. You are going to be amazed at the offers
               that we will be sending your way.</p>
              <p className={paragraphClasses}>And the best part?  There's <span className="underline">no contract or obligation</span>.  You can cancel at any time.</p>
              <p className={paragraphClasses}><span className="font-bold">Our promise to you:</span> Each month we will send you special offers that massively exceed the $5
              that you pay for your membership.  If, at any time, you no longer want to stay in the Sidewall Club, simply sign in to your account and cancel your
              Sidewall Club membership.</p>
              <p className="text-center mb-24">
                {/* <Link to="/join" className={joinBtnClasses}>Join Now</Link> */}
                <p className={fakeJoinBtnClasses}>New Memberships Available Soon</p>
              </p>
            </div>
          </div>
        </section>


        <section className="bg-white pt-8 px-4 md:px-0">
          <div className={containerClasses}>
            <div className={widthClasses}>
              <h3 className={subHeadlingClasses}>3 Awesome Bonuses Available Instantly When You Join Today</h3>
              <div className="flex flex-row">
                <div className="w-1/4 text-center">
                  <span className="text-indigo-700 font-bold" style={hugeNumberStyles}>01</span>
                </div>
                <div className="w-3/4 pl-6 md:pl-0">
                  <p className="uppercase font-bold text-2xl mb-4">Free 14" Pizza</p>
                  <p className={paragraphClasses}>This offer is available for dine in or take out and can be used for any pizza of your choice (up to $25).</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/4 text-center">
                  <span className="text-indigo-700 font-bold" style={hugeNumberStyles}>02</span>
                </div>
                <div className="w-3/4 pl-6 md:pl-0">
                  <p className="uppercase font-bold text-2xl mb-8">$15 GIFT CERTIFICATE FOR MONKEY WRENCH SMOKEHOUSE</p>
                  <p className={paragraphClasses}>This offer is available for dine in or take out and is available towards any food purchase.  Dine in or take out.</p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="w-1/4 text-center">
                  <span className="text-indigo-700 font-bold" style={hugeNumberStyles}>03</span>
                </div>
                <div className="w-3/4 pl-6 md:pl-0">
                  <p className="uppercase font-bold text-2xl mb-8">$10 GIFT CERTIFICATE FOR SIDEWALL PASTRY KITCHEN</p>
                  <p className={paragraphClasses}>Valid towards any purchase, including custom orders.</p>
                </div>
              </div>
              <p className="text-center mb-24 mt-12">
                {/* <Link to="/join" className={joinBtnClasses}>Join Now</Link> */}
                <p className={fakeJoinBtnClasses}>New Memberships Available Soon</p>
              </p>
            </div>
          </div>
        </section>


        <section className="bg-white py-12 px-4 md:px-0">
          <div className={containerClasses}>
            <div className={widthClasses}>
              <div className="pb-4">
                <h3 className={questionClasses}>Is this a great deal?</h3>
                <p className={answerClasses}>Heck yeah it is!  Just for joining the Sidewall Club, you are getting $50 of
                incredible value (see above).  And, that's just the beginning!</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>Can I purchase this membership at a later point in the year?</h3>
                <p className={answerClasses}>Most likely no.  We have a limited number of membership slots available in the Sidewall Club.
                Right now the joining period is open until December 22nd (or earlier if we hit our membership threshold earlier).</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>How will I receive the coupons?</h3>
                <p className={answerClasses}>When we release a new coupon, we will send you a text message with a link to your coupon.  You can also access all of your available coupons through your club member dashboard. </p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>How many coupons will I receive each month?</h3>
                <p className={answerClasses}>The number of coupons that we will send each month will vary.  You will always receive at least one coupon from us (and that coupon will provide you with an awesome value!).  During some months, we may send you multiple coupons.  In addition to coupons from us, we will send you coupons to other local businesses that we love to support.</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>Can I buy someone a Sidewall Club membership as a gift?</h3>
                <p className={answerClasses}>What a great friend!  Yes!  6-month gift memberships are available NOW for a limited time only.  So, if you want to get your friend their very own Sidewall Club membership as a gift, make your purchase before December 22nd.  Their gift membership will be valid through June 30, 2021.  And, they will also receive the special bonuses above.</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>Why are you sending the notifications via text and not email?</h3>
                <p className={answerClasses}>We are sending your coupons via text because we want to make sure you receive them.  We don’t want these great offers to get stuck in your email spam filter or buried in your overloaded inbox.</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>Will these be available for dine or take out?</h3>
                <p className={answerClasses}>You will be able to use most of the coupons for take out orders.  We may send you some coupons that are dine-in only offers.</p>
              </div>

              <div className="pb-4">
                <h3 className={questionClasses}>Can I cancel?</h3>
                <p className={answerClasses}>We’ll really miss you.  But, yes, you can cancel your membership at any time.</p>
                <p className={answerClasses}>Each month, we will send you special offers that massively exceed the $5 that you pay for your membership. If, at any time, you no longer want to stay in the Sidewall Club, simply sign in to your account and cancel your Sidewall Club membership.</p>
              </div>
              <p className="text-center mb-24 mt-12">
                {/* <Link to="/join" className={joinBtnClasses}>Join Now</Link> */}
                <p className={fakeJoinBtnClasses}>New Memberships Available Soon</p>
              </p>
            </div>
          </div>
        </section>

      </Layout>
    );
  }
}

export default IndexPage;
